import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default ({ location }) => (
  <Layout location={location}>
    <Helmet title="Livraison" />
    <section className="px-10 py-10" style={{ minHeight: 'calc(100vh - 80px - 415px' }}>
      <h1>Livraison</h1>
      <p>
        Exercitation nulla elit excepteur ullamco nostrud sunt consequat nulla tempor officia ipsum qui anim. Ad
        cupidatat ut esse esse reprehenderit cupidatat nostrud consequat velit. Ea enim aute ea est voluptate dolor.
        Nisi commodo exercitation qui elit pariatur laborum in amet consequat. Sit elit incididunt elit quis dolore ea
        Lorem exercitation ullamco sunt.
      </p>
      <p>
        Proident eiusmod Lorem sunt labore magna exercitation adipisicing pariatur exercitation dolore non pariatur
        exercitation reprehenderit. Velit minim ullamco veniam exercitation ut duis cupidatat non commodo mollit tempor
        est. Aute fugiat commodo dolor culpa ipsum nisi. Officia esse elit occaecat proident Lorem magna nulla aute
        nostrud sunt laboris. Anim cupidatat et anim ipsum consectetur veniam consequat ex irure duis do. Excepteur
        commodo commodo nostrud consectetur anim aliqua enim aliquip duis ipsum velit veniam mollit adipisicing. Anim
        magna Lorem laborum proident esse cupidatat do do.
      </p>
      <p>
        Anim est commodo commodo nulla minim. Et nulla anim deserunt pariatur mollit velit exercitation do excepteur
        occaecat qui proident consectetur enim. Ipsum sint qui sit labore aliquip mollit elit aute commodo sint. Ea id
        incididunt amet proident irure minim dolor occaecat aliqua occaecat. Irure incididunt non cupidatat amet officia
        pariatur voluptate laboris deserunt exercitation. Excepteur in enim anim labore qui voluptate incididunt enim
        dolore sint aliquip.
      </p>
    </section>
  </Layout>
);
